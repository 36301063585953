export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'GridIcon',
  },

  {
    title: 'Rota',
    route: 'scheduler',
    icon: 'CalendarIcon',
  },

  {
    title: 'Timesheet',
    route: 'approve-timesheet',
    icon: 'ClockIcon',
  },

  {
    title: 'Programmes',
    route: 'programmes',
    icon: 'ServerIcon',
  },

  {
    title: 'Locations',
    route: 'locations',
    icon: 'MapPinIcon',
  },

  {
    title: 'Network',
    route: 'networks',
    icon: 'GlobeIcon',
  },

  {
    title: 'Staff Bank',
    route: 'staff-bank',
    icon: 'UsersIcon',
  },

  {
    title: 'Payments',
    route: 'payments',
    icon: 'FileTextIcon',
  },

  // {
  //   title: 'Statistics',
  //   route: 'statistics',
  //   icon: 'PieChartIcon',
  // },

  {
    title: 'Settings',
    route: 'settings',
    icon: 'SettingsIcon',
  },

  // {
  //   title: 'Dashboard',
  //   route: 'dashboard',
  //   icon: 'HomeIcon',
  // },
  // {
  //   title: 'Scheduler',
  //   route: 'scheduler',
  //   icon: 'CalendarIcon',
  // },
  // {
  //   header: 'Patient',
  // },
  // {
  //   title: 'Patients',
  //   route: 'patients',
  //   icon: 'UsersIcon',
  // },
  // {
  //   title: 'Patients Attendance',
  //   route: 'patients-attendance',
  //   icon: 'FileTextIcon',
  // },
  // {
  //   header: 'Medicines',
  // },
  // {
  //   title: 'Items',
  //   route: 'items',
  //   icon: 'LayersIcon',
  // },
  // {
  //   title: 'Units',
  //   route: 'units',
  //   icon: 'DivideSquareIcon',
  // },
  // {
  //   header: 'Users',
  // },
  // {
  //   title: 'Members',
  //   route: 'members',
  //   icon: 'UsersIcon',
  // },
];
