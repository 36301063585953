import api from '../axios';
// const resource = '/api/pcn'

export default {
  // getNonConnectedLocation: () => api.get(`${resource}/non-connected-or-non-invited-practices`),
  getConnectionRequests: (page, paginate, filterQuery) =>
    api.get(
      `/api/pcn/requests?page=${page}&paginate=${paginate}&${filterQuery}`
    ),
  connectionRequestAccept: (id) => api.post(`/api/pcn/requests/${id}/accept`),
  connectionRequestReject: (id) => api.post(`/api/pcn/requests/${id}/decline`),
  getNonConnectedOrNonInvitedPractises: (page, paginate, filterQuery) =>
    api.get(
      `/api/pcn/non-connected-or-non-invited-practices?page=${page}&paginate=${paginate}&${filterQuery}`
    ),
  sendInvitationsViaPractiseId: (payload) =>
    api.post(`/api/pcn/invitations/via-practice-ids`, payload),
  getPcnDetails: () =>
    api.get(`/api/pcn/${sessionStorage.getItem('auth_pcn_id')}`),
  getNetworkPractises: (page, paginate, filterQuery) =>
    api.get(
      `/api/pcn/network-practices?${filterQuery}&page=${page}&paginate=${paginate}`
    ),
  getSummary: () => api.get(`/api/pcn/summary`),
  getInvites: (page, paginate, filterQuery) =>
    api.get(
      `/api/pcn/invitations/non-accepted?page=${page}&paginate=${paginate}&${filterQuery}`
    ),
  resendInvites: (id) => api.post(`/api/pcn/invitations/resend/${id}`),
  updatePractice: (id, payload) => api.post(`/api/pcn/${id}`, payload),
  getPcnTypes: (payload) => api.get('api/pcn/types'),
  getDetailsOfNetworkPractice: (id) => api.get(`api/pcn/network-practices/${id}`),

  getSalariedStaff: (id,page, paginate) => api.get(`api/pcn/network-practices/${id}/salaried-staff?&page=${page}&paginate=${paginate}`),
  getNetworkPracticeLocums: (id,page, paginate) => api.get(`api/pcn/network-practices/${id}/locums?&page=${page}&paginate=${paginate}`)
}

