import api from '../axios'
const resource = '/api/pcn/'


export default {

  getNotifications: (id) => api.get(`${resource}notifications?`),

  ReadNotification: ( id, payload) => api.put(`${resource}notifications/read/${id}`, payload),

  DeleteNotification: (id) => api.delete(`${resource}notifications/read/${id}`),

  ReadAllNotifications: (request) => api.put(`${resource}notifications/read-all`),



}
