<template>
  <b-nav-item-dropdown
    class="dropdown-notification"
    menu-class="dropdown-menu-media"
    style="list-style-type: none"
    right
  >
    <template #button-content>
      <feather-icon
        badge-classes="bg-danger"
        class="text-body"
        icon="GridIcon"
        size="21"
      />
    </template>

    <!-- Header -->

    <!--    <li class="dropdown-menu-header">-->
    <!--          <div class="dropdown-header d-flex">-->
    <!--                <h3 class="notification-title m-1 mr-auto font-weight-bolder dark">-->
    <!--                    Notifications-->
    <!--                </h3>-->
    <!--          </div>-->
    <!--    </li>-->

    <!-- Notifications -->
    <!--    v-if="notifications.length"-->
    <vue-perfect-scrollbar
     class="scrollable-container media-list scroll-area"
     style="height: 450px;border: 1px solid #E5E5E5;border-radius: 4px;"
     tagname="li"
   >
     <ul class="list-group">
       <li>
        <div
          v-if="getPcnAndNetwork.length !== 0"
          v-for="pcn in getPcnAndNetwork"
          :key="pcn.id"
          class="hoverable" 
        >
        <a
        :href="`${pcnAppUrl}/scheduler?auth_pcn_id=${pcn.pcn.id}`"
               target="_blank" >
          <b-row>
            <b-col cols="12" md="8">
            <img
              alt=""
              width="60px"
              class="img-fluid"
              src="@/assets/images/pages/img.png"
            />
                  <span class="subTopic"><b>{{ pcn.pcn.name }}</b></span>
                  <!-- <div class="subDescription">{{ pcn.pcn.name }}</div> -->
                </b-col>
                <b-col cols="12" md="4">
                  <div
                    class="status ml-2 mt-2 hoverable"
                    v-if="CheckIsCurrentPcn(pcn)"
                  >
                    <b>*Active</b>
                  </div>
                </b-col>
              </b-row>

        </a>
        </div>
          <div
          v-if="getPracticeAndNetwork.length !== 0"
          v-for="practice in getPracticeAndNetwork"
          :key="practice.id"
          @click="switchToPractice(practice)"
          class="hoverable"
        >
          <div class="d-inline-flex" id="listItems2">
            <img
              alt=""
              width="60px"
              class="img-fluid"
              src="@/assets/images/pages/iconac.png"
            />
            <div>
              <b-row>
                <b-col cols="12" md="11">
                  <div class="subTopic">
                    <b> {{ practice.name }}</b>
                  </div>
                  <!-- <div class="subDescription"> </div> -->
                </b-col>
                <b-col cols="12" md="1">
                  <!--                <div class="status ml-2" v-if='CheckIsCurrentPcn(pcn)' ><b>*Active</b></div>-->
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
       </li>
     </ul>
    </vue-perfect-scrollbar>
    
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BFormCheckbox,
  BRow,
  BCol,
  BCard,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import ProfileAPI from "@/apis/modules/profile";
import MomentMixin from "@/mixins/MomentMixin";
import { mapGetters } from "vuex";
import { mpAppUrl, pcnAppUrl } from "@/constants/config";

export default {
  components: {
    BRow,
    BCol,
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
    BCard,
  },
  directives: {
    Ripple,
  },
  data() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 20,
    };
    return {
      perfectScrollbarSettings,
      mpAppUrl,
      pcnAppUrl,
      user: "",
      pcnDetails: "",
    };
  },
  mixins: [MomentMixin],
  computed: {
    ...mapGetters(["currentUser", "getPracticeAndNetwork", "getPcnAndNetwork"]),
  },
  methods: {
    CheckIsCurrentPcn(pcn) {
      const current_pcn = Number(sessionStorage.getItem("auth_pcn_id"));
      return current_pcn === pcn.id;
    },
    async switchToPractice(practice) {
      const permissions = practice.team.roles.map((x) => {
        return x.name;
      });

      if (permissions.includes("practice-admin")) {
        window.open(
          `${mpAppUrl}/scheduler?practice_id=${practice.id}`,
          "_self"
        );
      } else {
        window.open(
          `${mpAppUrl}/my-shifts?practice_id=${practice.id}`,
          "_self"
        );
      }
    },
    // async switchToPcn(pcn) {
    //   const current_pcn = Number(sessionStorage.getItem("auth_pcn_id"));
    //   console.log(pcn)
    //   if (current_pcn === pcn.id) return;
    //   window.open(`${pcnAppUrl}/scheduler?auth_pcn_id=${pcn.id}`, "_blank");
    // },
  },
  mounted() {},
};
</script>

<style>
.header-navbar .navbar-container .dropdown-menu-media {
  width: 30rem;
}
.status {
  margin-top: 18px;
  font-size: 13px;
  font-weight: bold;
  margin-left: 10px;
  float: left;
  color: darkgreen;
}
.hoverable:hover {
  background-color: #f2f2f2;
}
</style>
