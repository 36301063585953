<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>






    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <h3 class=" ml-1 mb-0">{{$route.meta.title}}</h3>
<!--      <dark-Toggler class="d-none d-lg-block" />-->
    </div>


    <!-- Message Icon -->

<!--    <div class="demo-inline-spacing  bg-white mt-n2 ">-->
<!--      <feather-icon-->
<!--        icon="MessageSquareIcon"-->
<!--        size="18"-->
<!--        class="text-dark"-->
<!--      />-->
<!--    </div>-->

    <!-- BellIcon -->
    <div class="demo-inline-spacing  bg-transparent mt-n2 ">
     <NotificationIcon/>
    </div>
<!--    switch app-->
    <div class="demo-inline-spacing  bg-transparent mt-n2 ">
      <div class="hoverable">
        <AppDropdown/>
      </div>
    </div>



    <b-navbar-nav class="nav align-items-center ml-auto">
      <!--      <attendance-dropdown />-->
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
<!--            <p
              v-if="currentUser"
              class="user-name font-weight-bolder mb-0"
            >
              {{ currentUser.user.name }}

            </p>-->
            <p
                class="user-name  mb-0 font-weight-700"
            >
              {{currentUser.user.first_name +' '+ currentUser.user.last_name}}

            </p>
            <span class="user-status">{{pcnDetails.name}}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="getUserImage(currentUser.user.id)"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <div>
          <div>
            <div class="userImg">
              <b-avatar
                  size="60"
                  variant="light-primary"
                  badge
                  :src="getUserImage(currentUser.user.id, true)"
                  class="badge-minimal align-items-center"
                  badge-variant="success"
              />
            </div>
            <div class="userName">
              <b>{{ currentUser.user.first_name }} {{ currentUser.user.last_name }}</b>
            </div>
            <div class="userMail">
              {{ currentUser.user.email }}
            </div>
            <div class="goToDashboard">
              <!-- <p><button @click="profileAccountChange()" class="text-info">Account Dashboard</button></p> -->
            </div>
            <div class="viewUserAccount">
              <p><button @click="$router.push(`/staff-bank`)" class="btn manageBtn">Manage your account</button></p>
            </div>

            <b-dropdown-divider />
            <div class="ItemsStylish">
              <!-- <div class="d-inline-flex" id="listItems" v-if="checkIsSalriedStaffForTab()" @click="changeSalaried">
                <img
                    alt=""
                    width="50px"
                    class="img-fluid"
                    src="@/assets/images/pages/iconac.png"
                />
                <div>
                  <div class="row">
                    <div class="col-md-10">
                      <div class="subTopic"><b>Staff App</b></div>
                      <div class="subDescription">{{practiceDetails.name}}</div>
                    </div>
                    <div class="col-md-2">
                      <div class="status" v-if='checkIsSalaried()' ><b>*Active</b></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-inline-flex" v-if="checkIsAdminForTab()" @click="changeAdmin">
                <img
                    alt=""
                    width="50px"
                    class="img-fluid"
                    src="@/assets/images/pages/iconab.png"
                />
                <div>
                  <div class="row">
                    <div class="col-md-10">
                      <div class="subTopic"><b>Practice App</b></div>
                      <div class="subDescription">{{practiceDetails.name}}</div>
                    </div>
                    <div class="col-md-2">
                      <div class="status" v-if='checkIsAdmin()'><b>*Active</b></div>
                    </div>
                  </div>
                </div>
              </div> -->

              <div class="row">
                <div class="col">
                  <p class="dashboaerdLink"><a @click="profileAccountChange()" class="text-info">Go to Account Dashboard</a></p>
                </div>

              </div>
            </div>
            <b-dropdown-divider />
            <div class="logoutButton">
              <b-button
                  class="btn manageBtn"
                  type="submit"
                  variant="white"
                  @click="localLogout()"
              >
                Logout of all account
              </b-button>
            </div>
          </div>
        </div>



      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BButton, BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import { mapActions, mapGetters } from 'vuex'
import NotificationIcon from './NotificationDropdown'
import profileAPI from '@/apis/modules/profile';
import connectionRequestsAPI from "@/apis/modules/connectionRequests";
import { mpAppUrl, pcnAppUrl,  loginAppUrl } from '@/constants/config';
import AppDropdown from "@/layouts/components/AppDropdown";


export default {
  components: {
    BButton,
    AppDropdown,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    NotificationIcon,
    VuePerfectScrollbar,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },

  data(){
    const perfectScrollbarSettings = {
      maxScrollbarLength: 20,
    };
    return{
       mpAppUrl,
      pcnAppUrl,
      user: null,
      pcnDetails:'',
      perfectScrollbarSettings,
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'getPracticeAndNetwork',
      'getPcnAndNetwork',
    ]),
  },
  methods: {
    async switchToPractice(practice) {
      sessionStorage.removeItem('practice_id');
      if (this.permissions.includes('practice-admin')) {
        window.open(
          `${mpAppUrl}/my-shifts?practice_id=${practice.id}`,
          '_self'
        );
      } else {
        window.open(
          `${mpAppUrl}/my-shifts?practice_id=${practice.id}`,
          '_self'
        );
      }
    },
    async switchToPcn(pcn) {
      sessionStorage.removeItem('auth_pcn_id');
      window.open(`${pcnAppUrl}/scheduler?auth_pcn_id=${pcn.id}`, '_blank');
    },
    ...mapActions(['logout']),
    async localLogout() {
      await this.logout()
      window.open(loginAppUrl, '_parent');
    },
    profileAccountChange(){
      // window.location.href = `https://www.hyre-account.locumboxapp.com/`

      window.open(loginAppUrl, '_parent');

    },
    async getAuthUser(){
      try{
        const response = await profileAPI.getCurrentUserDetail();
        this.user = response.data.data
      }catch(error){
        this.convertAndNotifyError(error)
      }
    },
    async getPcnDetails(){
      try{
        const response = (await connectionRequestsAPI.getPcnDetails()).data.data
        this.pcnDetails = response
      }catch(erro){
      }
    },

  },

  mounted () {
    this.getAuthUser()
    this.getPcnDetails()
  }
}
</script>
<style >
.header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu {
  width: 21rem;
}
.userImg {
  margin-left: 115px;
  margin-top: 5px;
}

.subTopic {
  margin-top: 14px;
  font-size: 13px;
  color: black;
}

.subDescription {
  font-size: 8px;
  margin-top: 5px;
  color: rgb(131, 127, 127);
}

.userName {
  text-align: center;
  color: black;
  margin-top: 15px;
  font-size: 15px;
}

.userMail {
  text-align: center;
  margin-top: 3px;
  font-size: 12px;
}

.viewUserAccount {
  text-align: center;
  font-size: 12px;

}
.status{
  margin-top: 18px;
  font-size: 10px;
  margin-left: 10px;
  float: left;
  color: darkgreen;
}

.ItemsStylish{
  cursor: pointer;
  text-align: center;
}

.logoutButton{
  text-align: center;
  margin-top: 15px;
  margin-bottom: 10px;
}

.goToDashboard{
  text-align: center;
  margin-top: 15px;
  font-size: 12px;
}

.manageBtn{
  border-radius: 12px;
  background: transparent;
  border-color: rgb(75, 75, 75);
  height: 35px;
  padding-bottom: 7px;
  padding-top: 6px;
  color: black;
}

.dashboaerdLink{
  margin-top: 5px;
  margin-bottom: 5px;
}
.hoverable:hover {
  background-color: #f2f2f2;
}
</style>
